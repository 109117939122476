import React, { Component } from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"

// Bootstrap components
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

// Persosa components
import Markdown from "../components/Markdown"
import Navigation from "../components/Navigation"
import CloudflareStream from "./CloudflareStream"

// Other assets
import PersosaLogo from "../assets/images/Logo-White.svg"
import HeroArrow from "../assets/images/icon-arrow-drop-down-circle.svg"

var classNames = require("classnames")

class Hero extends Component {
  render() {
    const hero = this.props.hero
    const isHome = this.props.isHome
    const navbar = this.props.navbar
    const hasImage = hero.backgroundImage

    const returnBackgroundImage = () => {
      if (
        hero.backgroundImage &&
        hero.backgroundImage.file &&
        hero.backgroundImage.file.url
      ) {
        return "url(" + hero.backgroundImage.file.url + ")"
      } else {
        return ""
      }
    }

    var heroClass = classNames({
      hero: true,
      "is-home": isHome,
      "has-image": hasImage,
    })

    return (
      <div className={heroClass}>
        <div
          className="hero-image"
          style={{ backgroundImage: returnBackgroundImage() }}
        ></div>

        {isHome &&
          this.props.hero.backgroundVideo &&
          this.props.hero.backgroundVideo.backgroundVideo && (
            <div className="stream-wrapper">
              <CloudflareStream
                videoId={this.props.hero.backgroundVideo.backgroundVideo}
                controls={false}
                autoPlay={true}
                loop={true}
                mute="true"
              />
            </div>
          )}

        {!isHome &&
          this.props.hero.backgroundVideo &&
          this.props.hero.backgroundVideo.backgroundVideo && (
            <div className="stream-wrapper">
              <CloudflareStream
                videoId={this.props.hero.backgroundVideo.backgroundVideo}
                autoPlay={undefined}
                controls={true}
                loop={false}
                mute="false"
              />
            </div>
          )}

        <div className="hero-overlay"></div>

        <Container fluid className="hero-nav">
          <Navigation menus={navbar.menus} />
        </Container>

        <Container className="hero-content">
          <Row>
            <Col lg={7}>
              {!isHome && <h6>{hero.label}</h6>}
              {isHome && (
                <img alt="Persosa" className="hero-logo" src={PersosaLogo} />
              )}

              <h1>{hero.title}</h1>

              <div className="hero-description">
                <Markdown content={hero.content} />
              </div>
            </Col>
          </Row>
        </Container>

        {isHome && (
          <AnchorLink href="#content">
            <img alt="" className="hero-arrow" src={HeroArrow} />
          </AnchorLink>
        )}
      </div>
    )
  }
}

export default Hero
