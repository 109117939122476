import React, { Component } from "react"

// Bootstrap components
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

class CTA extends Component {
  render() {
    const cta = this.props.cta;
    return (
      <div className="cta">
				<Container>
					<Row>
						<Col lg={{span:6, offset:3}}>
			        <h2>{cta.title}</h2>
							<Button href={cta.buttonCTA.link} variant="outline-light">{cta.buttonCTA.label}</Button>
						</Col>
					</Row>
				</Container>
      </div>
    )
  }
}

export default CTA
